import AppSDK from "@growthos/ui-app-sdk";

class ApiApp {
	constructor() {
		throw new Error("Unable to create instance of static class");
	}

	static getHomeData({ abortController } = {}) {
		return AppSDK.fetchWithAuth(`/data/home.json`, {
			method: "GET",
			abortController
		})
			.then((response) => response.json())
			.then((home) => {
				return Promise.all(home.moduleIds.map((moduleId) => ApiApp.getModuleData(moduleId, { abortController }))).then((moduleData) => {
					return {
						home,
						...home.moduleIds.reduce(
							(modules, moduleId, index) => ({
								...modules,
								[moduleId]: moduleData[index]
							}),
							{}
						)
					};
				});
			});
	}

	static getModuleData(moduleName, { abortController } = {}) {
		return AppSDK.fetchWithAuth(`/data/modules/${moduleName}.json`, {
			method: "GET",
			abortController
		}).then((response) => response.json());
	}
}
export default ApiApp;
