import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import AppSDK from "@growthos/ui-app-sdk";

import App from "./App";

import packageJson from "../package.json";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const appElementId = "app";

const Body = (
	<AppSDK.ShellProvider onRender={onRenderHandler}>
		<AppSDK.ErrorHandler>
			<BrowserRouter basename={baseUrl}>
				<App />
			</BrowserRouter>
		</AppSDK.ErrorHandler>
	</AppSDK.ShellProvider>
);

// Welcome message
console.log(`App: ${packageJson.name} v${packageJson.version}`);

function onRenderHandler(app) {
	const appEl = document.getElementById(appElementId);
	createRoot(appEl).render(app);
}
onRenderHandler(Body);
