import React, { useState, useEffect, useMemo } from "react";
import { Routes, Route } from "react-router-dom";

import AppSDK from "@growthos/ui-app-sdk";
import { AnalyticsProvider } from "@growthos/ui-analytics";
import { Page404 } from "@growthos/ui-theme";

import { routes } from "./router/routes";

import "./App.scss";

const APP_CODE = process.env.REACT_APP_APP_CODE;

const App = () => {
	const [stateIsReady, setStateIsReady] = useState(AppSDK.getIsReady());

	// AppSDK can provide information after onReady
	// See AppSDK documentation for more information
	useEffect(() => {
		AppSDK.onReady(({ contextId, context, briefId, taskId, currentApp }) => {
			console.log("App::onReady", { contextId, context, briefId, taskId, currentApp });
			setStateIsReady(true);
		});
		AppSDK.initialize({ appCode: APP_CODE }).catch((err) => AppSDK.ErrorHandler.caught(err, true));
	}, []);

	// Get information from AppSDK - values will be null until onReady fires
	const { user, contextId, context, briefId, currentApp } = useMemo(() => {
		return {
			isReady: stateIsReady,
			user: AppSDK.ApiUser.getUser(),
			contextId: AppSDK.getContextId(),
			context: AppSDK.getContext(),
			briefId: AppSDK.getBriefId(),
			taskId: AppSDK.getTaskId(),
			currentApp: AppSDK.getCurrentApp()
		};
	}, [stateIsReady]);

	return (
		<>
			{stateIsReady && (
				<AnalyticsProvider
					pendoApiKey={"93b796da-0cfd-482e-4a5a-20511c9ec7a4"}
					contextId={contextId}
					context={context}
					briefId={briefId}
					currentApp={currentApp}
					user={user}
				>
					<Routes>
						{routes.map((route, routeIndex) => (
							<Route {...route} key={routeIndex} />
						))}
						<Route path="*" element={<Page404 />} />
					</Routes>
				</AnalyticsProvider>
			)}
		</>
	);
};
export default App;
